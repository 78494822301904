/**
 * Edge Button constants
 */

import Validator from "../../utils/Validator";

/** default width/height in pixels of an edge button host */
const DEFAULT_WIDTH = 11;
/** default edge button size in pixels */
const DEFAULT_SIZE = 42;

/** ordinal number of left edge */
const EDGE_LEFT = 0;
/** ordinal number of top edge */
const EDGE_TOP = 1;
/** ordinal number of right edge */
const EDGE_RIGHT = 2;
/** ordinal number of bottom edge */
const EDGE_BOTTOM = 3;

/**
 * checks whether an edge number is valid
 * @param {Number} e edge number
 * @returns {Boolean} true if the given number is a valid edge number; false otherwise
 */
function isValidEdge(e) {
    return Validator.isInteger(e) && (EDGE_LEFT <= e) && (e <= EDGE_BOTTOM);
}

export { DEFAULT_WIDTH, DEFAULT_SIZE, EDGE_LEFT, EDGE_TOP, EDGE_RIGHT, EDGE_BOTTOM, isValidEdge };