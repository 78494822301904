import Base from '../../../base/base';
// do NOT do this (it will lead to runtime errors due to an invalid class order): import XtwModel from './XtwModel';

/**
 * abstract row item class
 * @abstract
 */
export default class MRowItem extends Base {

	/**
	 * constructs a new instance
	 * @param {XtwModel} model the model to which this item belongs
	 * @param {Object} JSON data sent by the web server
	 */
	constructor( model, json ) {
		super();
		this._xtwModel = model;
		const idt = json.idg || 0; // --- !!! yes, json.idg !!! ---
		const idr = json.idr || 0;
		const xid = json.xid || 0;
		Object.defineProperty( this, "_idt", { value: idt, writable: false, configurable: false } );
		Object.defineProperty( this, "_idr", { value: idr, writable: false, configurable: false } );
		Object.defineProperty( this, "_xid", { value: xid, writable: false, configurable: false } );
		if ( this.idr !== this.xid ) {
			// that's not (longer) allowed!
			throw new Error( 'Different row ID values are not allowed!' );
		}
		this._height = json.height || 0;
		this._ovrHeight = null;
		this._flatIndex = -1;
		this.vPadding = 0;
		this._present = false;
		this.top = 0;
	}

	/**
	 * @returns {XtwModel} the model to which this item belongs
	 */
	get model() {
		return this._xtwModel;
	}

	/**
	 * @returns {Number} the target ID
	 */
	get idt() {
		return this._idt;
	}

	/**
	 * @returns {Number} the row ID
	 */
	get idr() {
		return this._idr;
	}

	/**
	 * @returns {Number} the extra ID
	 */
	get xid() {
		return this._xid;
	}

	/**
	 * @returns {Boolean} the "present" status
	 */
	get present() {
		return this._present;
	}

	/**
	 * @returns {Boolean} true if this row is in "edit" mode; false otherwise
	 */
	get edited() {
		return false;
	}

	/**
	 * @returns {Number} the height of the row item
	 */
	get height() {
		return this._height;
	}

	/**
	 * changes the height of the row item
	 */
	set height( newValue ) {
		this._height = newValue;
	}

	/**
	 * @returns {Number} the overridden height of the row item
	 */
	get ovrHeight() {
		return this._ovrHeight;
	}

	/**
	 * changes the overridden height of the row item
	 */
	set ovrHeight( newValue ) {
		this._ovrHeight = newValue;
	}

	/**
	 * @override
	 */
	doDestroy() {
		super.doDestroy();
		delete this._ovrHeight;
		delete this._height;
		delete this._xtwModel;
	}

	/**
	 * @returns {Number} the index of this item in the flat model
	 */
	get flatIndex() {
		return this._flatIndex;
	}

	/**
	 * gets whether this item is selected or not
	 * @return {Boolean} true if item selected, false otherwise
	 */
	get isSelected() {
		return this.alive && !this.isGroupHead() && this.model.isItemSelected(this);
	}

	/**
	 * gets whether this item is focused or not
	 * @return {Boolean} true if item focused, false otherwise
	 */
	get isFocused() {
		return this.alive && this.model.isItemFocused(this);
	}

	/**
	 * indicates whether this row item is "present", i.e. whether it has received data from web server
	 * @returns {Boolean} true if the row item valid data; false otherwise
	 */
	isPresent() {
		return this.present;
	}

	/**
	 * @returns {Number} the row ID
	 */
	getRowID() {
		return this.idr;
	}

	/**
	 * @returns {Number} the group ID of the target group to which this item belongs
	 */
	getTgtID() {
		return this.idt;
	}

	/**
	 * @returns {Number} the extra ID
	 */
	getExtraID() {
		return this.xid;
	}

	/**
	 * @returns {Boolean} true if this item is a group head item; false otherwise
	 */
	isGroupHead() {
		return false;
	}

	/**
	 * @returns {Boolean} true if this item is a data row; false otherwise
	 */
	isDataRow() {
		return false;
	}

	/**
	 * @returns {Boolean} true if this item is the "dummy" row; false otherwise
	 */
	isDummyRow() {
		return false;
	}

	/**
	 * indicates whether this item has child items
	 * @returns {Boolean} true if this item has child items; false otherwise
	 */
	hasChildren() {
		return false;
	}

	/**
	 * @returns {Number} the height in pixels of this item
	 */
	getHeight() {
		return this.ovrHeight !== null ? this.ovrHeight : this.height;
	}

	/**
	 * @returns {Number} the total vertical padding in pixels
	 */
	getVPadding() {
		return this.vPadding;
	}

	/**
	 * sets the "overriden" height property
	 * @param {Number} ovh the overriden height of this item
	 * @param {Number} vpad vertical padding
	 */
	setOvrHeight( ovh, vpad ) {
		if ( ovh ) {
			this.ovrHeight = ovh;
			this.vPadding = vpad;
		} else {
			this.ovrHeight = null;
			this.vPadding = 0;
		}
	}

	/**
	 * @returns {Number} the top coordinate of this item
	 */
	getTop() {
		return this.top;
	}

	/**
	 * sets a new top coordinate
	 * @param {Number} t new top coordinate
	 */
	setTop( t ) {
		this.top = t || 0;
	}

	/**
	 * processes new data sent by the application server
	 * @param {Object} data item's data as provided by the application server
	 */
	setData( data ) {
		// this method does nothing here
	}

	/**
	 * updates cell data
	 * @param {*} cd new cell data
	 */
	updCell(cd) {
		// this method does nothing here
	}

	/**
	 * invalidates this item so new data must be requested
	 */
	invalidate() {
		this._present = false;
	}

	/**
	 * clears the "edited" status
	 */
	clearEdited() {
		// abstract
	}
}

console.debug("widgets/xtw/model/MRowItem.js loaded");