/**
 * EdgeHost - an edge button host
 */

import LoggingBase from "../../base/loggingbase";
import HtmHelper from "../../utils/HtmHelper";
import { EDGE_BOTTOM, EDGE_LEFT, EDGE_RIGHT, EDGE_TOP, isValidEdge } from "./EBConst";
import EdgeButtons from "./EdgeButtons";

const CHEVRON_LEFT = '<i class="fal fa-chevron-double-left"></i>';
const CHEVRON_RIGHT = '<i class="fal fa-chevron-double-right"></i>';
const CHEVRON_DOWN = '<i class="fal fa-chevron-double-down"></i>';
const CHEVRON_UP = '<i class="fal fa-chevron-double-up"></i>';

export default class EdgeHost extends LoggingBase {

    /**
     * constructs a new instance
     * @param {EdgeButtons} widget the edge buttons widget
     * @param {Number} edge the edge number
     * @param {Number} width width/height in pixels of the edge button host element
     * @param {Number} size size in pixels of the edge button
     */
    constructor(widget, edge, width, size) {
        super('widgets.edgebuttons.EdgeHost');
        if ( !isValidEdge(edge) ) {
            throw new Error(`Invalid edge: ${edge}!`);
        }
        this._widget = widget;
        this._edge = edge;
        this._available = false;
        this._visible = false;
        this._button = null;
        this._inner = null;
        this._direction = false;
        this._element = this._createElement(edge, width, size);
    }

    /**
     * @returns {EdgeButtons} the edge buttons widget
     */
    get widget() {
        return this._widget;
    }

    /**
     * @returns {Number} the edge number
     */
    get edge() {
        return this._edge;
    }

    /**
     * @returns {Boolean} the "available" state
     */
    get available() {
        return this._available;
    }

    /**
     * @returns {Boolean} the "visible" state
     */
    get visible() {
        return this._visible;
    }

    /**
     * @returns {Boolean} the "active" state
     */
    get active() {
        return this.alive && this.available && this.visible;
    }

    /**
     * @returns {HTMLElement} the DOM element
     */
    get element() {
        return this._element;
    }

    /**
     * @returns {HTMLElement} the button element
     */
    get button() {
        return this._button;
    }

    /**
     * @returns {HTMLElement} the button's inner element
     */
    get inner() {
        return this._inner;
    }

    /**
     * @returns {Boolean} the current direction: false => inside; true => outside
     */
    get direction() {
        return this._direction;
    }

    /**
     * @inheritdoc
     * @override
     */
    doDestroy() {
        HtmHelper.rmvDomElm(this._element);
        this._element = null;
        super.doDestroy();
    }

    /**
     * sets the "available" state
     * @param {Boolean} avl new value
     */
    setAvailable(avl) {
        if ( this.available !== avl ) {
            this._available = !!avl;
            this.log(`EDGE${this.edge}: available is set to = "${avl}"`);
            this._updateState();
        }
    }

    /**
     * sets the "visible" state
     * @param {Boolean} vis new value
     */
    setVisible(vis) {
        if ( this.visible !== vis ) {
            this._visible = !!vis;
            this.log(`EDGE${this.edge}: visible is set to = "${vis}"`);
            this._updateState();
        }
    }

    /**
     * changes the direction of the edge button
     * @param {Boolean} dir new direction flag
     */
    setDirection(dir) {
        if ( this.direction !== dir ) {
            this.log(`EDGE${this.edge}: new direction = "${(dir ? 'outside' : 'inside')}"`);
            this._direction = !!dir;
            const btn = this.button;
            const inner = this.inner;
            switch ( this.edge ) {
                case EDGE_LEFT:
                    btn.classList.remove(dir ? 'edgeButtonLeftInside' : 'edgeButtonLeftOutside');
                    btn.classList.add(dir ? 'edgeButtonLeftOutside' : 'edgeButtonLeftInside');
                    inner.innerHTML = dir ? CHEVRON_LEFT : CHEVRON_RIGHT;
                    break;
                case EDGE_RIGHT:
                    btn.classList.remove(dir ? 'edgeButtonRightInside' : 'edgeButtonRightOutside');
                    btn.classList.add(dir ? 'edgeButtonRightOutside' : 'edgeButtonRightInside');
                    inner.innerHTML = dir ? CHEVRON_RIGHT : CHEVRON_LEFT;
                    break;
                case EDGE_TOP:
                    btn.classList.remove(dir ? 'edgeButtonTopInside' : 'edgeButtonTopOutside');
                    btn.classList.add(dir ? 'edgeButtonTopOutside' : 'edgeButtonTopInside');
                    inner.innerHTML = dir ? CHEVRON_UP : CHEVRON_DOWN;
                    break;
                case EDGE_BOTTOM:
                    btn.classList.remove(dir ? 'edgeButtonBottomInside' : 'edgeButtonBottomOutside');
                    btn.classList.add(dir ? 'edgeButtonBottomOutside' : 'edgeButtonBottomInside');
                    inner.innerHTML = dir ? CHEVRON_DOWN : CHEVRON_UP;
                    break;
            }
        }
    }

    /**
     * sets a fixed position and size
     * @param {Number} beg the "beginning" position in pixels, i.e. the top coordinate for vertical edge buttons and the horizontals coordinate for horizontal edge buttons
     * @param {Number} size the size if pixels of the edge button host element
     */
    setFixedPos(beg, size) {
        if ( this.alive ) {
            const he = this.element;
            if ( he instanceof HTMLElement ) {
                if ( this.isDebugEnabled() ) {
                    this.log(`EDGE${this.edge}: fixed position = ${beg}px; fixed size = ${size}px.`);
                }
                const edge = this.edge;
                const style = he.style;
                if ( (edge === EDGE_LEFT) || (edge === EDGE_RIGHT) ) {
                    style.top = `${beg}px`;
                    style.height = `${size}px`;
                    style.bottom = 'unset';
                } else {
                    style.left = `${beg}px`;
                    style.width = `${size}px`;
                    style.right = 'unset';
                }
            }
        }
    }

    /**
     * creates the DOM element
     * @param {Number} edge the edge number
     * @param {Number} width width/height in pixels of the edge button host element
     * @param {Number} size size in pixels of the edge button
     * @returns {HTMLElement} the DOM element
     */
    _createElement(edge, width, size) {
        const he = document.createElement('div');
        he.classList.add('edgeBtnHost');
        {   // setup host element
            const style = he.style;
            style.display = 'none';                     // initially invisible!
            if ( (edge == EDGE_LEFT) || (edge == EDGE_RIGHT) ) {
                he.classList.add('edgeBtnHostVert');
                style.width = `${width}px`;
            } else {
                he.classList.add('edgeBtnHostHorz');
                style.height = `${width}px`;
            }
            switch ( edge ) {
                case EDGE_LEFT:
                    style.left = '0';
                    break;
                case EDGE_RIGHT:
                    style.right = '0';
                    break;
                case EDGE_TOP:
                    style.top = '0';
                    break;
                case EDGE_BOTTOM:
                    style.bottom = '0';
                    break;
            }
        }
        const btn = document.createElement('div');
        btn.classList.add('edgeButton');
        {   // setup button element
            const style = btn.style;
            style.flexBasis = `${size}px`;
            if ( (edge == EDGE_LEFT) || (edge == EDGE_RIGHT) ) {
                btn.classList.add('edgeButtonVert');
                style.minHeight = `${size}px`;
            } else {
                btn.classList.add('edgeButtonHorz');
                style.minWidth = `${size}px`;
            }
            switch ( edge ) {
                case EDGE_LEFT:
                    btn.classList.add('edgeButtonLeftInside');
                    break;
                case EDGE_RIGHT:
                    btn.classList.add('edgeButtonRightInside');
                    break;
                case EDGE_TOP:
                    btn.classList.add('edgeButtonTopInside');
                    break;
                case EDGE_BOTTOM:
                    btn.classList.add('edgeButtonBottomInside');
                    break;
            }
        }
        const inner = document.createElement('div');
        inner.classList.add('edgeButtonInner');
        {   // setup the "inner" element
            const style = inner.style;
            style.fontSize = `${width-1}px`;
            if ( (edge == EDGE_LEFT) || (edge == EDGE_RIGHT) ) {
                inner.classList.add('edgeButtonInnerVert');
                style.minHeight = `${size}px`;
            } else {
                inner.classList.add('edgeButtonInnerHorz');
                style.minWidth = `${size}px`;
            }
            switch ( edge ) {
                case EDGE_LEFT:
                    inner.innerHTML = CHEVRON_RIGHT;
                    break;
                case EDGE_RIGHT:
                    inner.innerHTML = CHEVRON_LEFT;
                    break;
                case EDGE_TOP:
                    inner.innerHTML = CHEVRON_DOWN;
                    break;
                case EDGE_BOTTOM:
                    inner.innerHTML = CHEVRON_UP;
                    break;
            }
        }
        btn.appendChild(inner);
        this._button = btn;
        this._inner = inner;
        const self = this;
        btn.addEventListener('click', () => self._onBtnClick());
        he.appendChild(btn);
        document.body.appendChild(he);
        return he;
    }

    /**
     * updates the visibility state
     */
    _updateState() {
        this.element.style.display = this.active ? '' : 'none';
    }

    /**
     * handles clicks on the button
     */
    _onBtnClick() {
        if ( this.alive ) {
            if ( this.isDebugEnabled() ) {
                this.log(`EDGE${this.edge}: button clicked.`);
            }
            // just notify the widget
            this.widget.onEdgeClicked(this.edge);
        }
    }
}