import PSA from '../../psa';
import Color from '../../utils/Color';
import DomEventHelper from '../../utils/DomEventHelper';
import HtmHelper from '../../utils/HtmHelper';
import ExternalEventsTransferManager from '../../utils/ExternalEventsTransferManager';
import Validator from '../../utils/Validator';
import JsSize from '../../utils/JsSize';
import AutoFitModeManager from './impl/autofit/AutoFitModeManager';
import XtwMgr from './util/XtwMgr';
import XtwUtils from './util/XtwUtils';
import XtwHead from './XtwHead';
import XtwBody from './XtwBody';
import LoggingBase from '../../base/loggingbase';

export const ROW_HEIGHT_CSS_VARIABLE = "--rtp-table-row-height";
export const HEADER_HEIGHT_CSS_VARIABLE = "--rtp-table-header-height";
const BODY_HEIGHT_CSS_VARIABLE = "--rtp-table-body-height";

const COLOR_PROPERTIES_TO_CSS_VARS = new Map();
COLOR_PROPERTIES_TO_CSS_VARS.set( "pyj", "--rtp-table-main-pyjama-color" );
COLOR_PROPERTIES_TO_CSS_VARS.set( "hgc", "--rtp-border-bottom-color" );
COLOR_PROPERTIES_TO_CSS_VARS.set( "vgc", "--rtp-border-right-color" );
COLOR_PROPERTIES_TO_CSS_VARS.set( "ghb", "--rtp-group-header-background-color" );
COLOR_PROPERTIES_TO_CSS_VARS.set( "ght", "--rtp-group-header-text-color" );
COLOR_PROPERTIES_TO_CSS_VARS.set( "hlc", "--rtp-header-border-right-color" );
COLOR_PROPERTIES_TO_CSS_VARS.set( "hbc", "--rtp-header-background-color" );
COLOR_PROPERTIES_TO_CSS_VARS.set( "htc", "--rtp-header-text-color" );
COLOR_PROPERTIES_TO_CSS_VARS.set( "scc", "--pisa-tblColSelBgc" );
const LENGTH_PROPERTIES_TO_CSS_VARS = new Map();
LENGTH_PROPERTIES_TO_CSS_VARS.set( "rwh", ROW_HEIGHT_CSS_VARIABLE );
LENGTH_PROPERTIES_TO_CSS_VARS.set( "hlw", "--rtp-border-bottom-width" );
LENGTH_PROPERTIES_TO_CSS_VARS.set( "vlw", "--rtp-border-right-width" );
LENGTH_PROPERTIES_TO_CSS_VARS.set( "thh", HEADER_HEIGHT_CSS_VARIABLE );
LENGTH_PROPERTIES_TO_CSS_VARS.set( "grh", "--rtp-group-header-height" );
LENGTH_PROPERTIES_TO_CSS_VARS.set( "hvw", "--rtp-header-border-right-width" );

const MINIMAL_COLUMN_WIDTH = 10;
const MINIMAL_ROW_HEIGHT = 4;

/** minimal scrollbar's thumb size */
const MIN_THUMB_SIZE = 17;

/** auto scrolling interval in [ms] */
const AUTO_SCROLL_INTERVAL = 250;


/**
 * class XtwTbl - the master component of eXtended Table Widget (XTW);
 * this class is pretty simple because it is just the container managing the global layout and nothing else
 */
export default class XtwTbl extends LoggingBase {

	/**
	 * constructs a new instance
	 * @param {*} properties initialization arguments
	 */
	constructor( properties ) {
		super('widgets.xtw.XtwTbl');
		this._psa = PSA.getInst();
		this._psa.bindAll( this, [ "layout", "onReady", "onRender" ] );
		this.ready = false;
		// initialize the size cache
		this.size = new JsSize( -1, -1 );
		// get the RAP parent element
		const idw = properties.parent;
		this.wdgId = idw;
		this.parent = rap.getObject( idw );
		this.parElm = null; // not yet available
		// add the resize listener
		this.parent.addListener( "Resize", this.layout );
		// activate "render" event
		rap.on( "render", this.onRender );
		// evaluate custom widget data - we need access to our scrollbars (a.k.a "sliders") and more
		const cwd = this.parent.getData( "pisasales.CSTPRP.CWD" ) || {};
		this.wdgIdStr = cwd.idstr || '';
		this.idwSlv = cwd.slv || '';
		this.idwSlh = cwd.slh || '';
		this.wdgSlv = null;
		this.wdgSlh = null;
		this._vScbVis = false;
		this._ascTimer = null;
		// get grid colors
		this.clrVtg = cwd.vgc || null;
		this.clrHzg = cwd.hgc || null;
		// register this instance
		XtwMgr.getInst().addXtdTbl( this );
		// later, we'll get our table header widget
		this.wdgHead = null;
		// later, we'll get or table body widget
		this.wdgBody = null;
		// later, we'll create the column drag DIV
		this.cdrElm = null;
		// column drag info
		this.colDrg = null;
		// Row Template
		this.rowTpl = null;
		this.rtpMode = false;
		this.rtpTgl = false;
		// logical "RAP" focus
		this.rapFocus = false;
		if ( Validator.isPositiveNumber( cwd.tss, false ) ) {
			Object.defineProperty( this, "verticalSliderOriginalWidth", {
				value: cwd.tss,
				writable: false,
				configurable: false
			} );
		}
		const minimalColumnWidth = Validator.isPositiveNumber( cwd.mcw ) ? cwd.mcw : MINIMAL_COLUMN_WIDTH;
		Object.defineProperty( this, "minimalColumnWidth", {
			value: minimalColumnWidth,
			writable: false,
			configurable: false
		} );
		new AutoFitModeManager( this, !!cwd.afm );
	}

	get tableHeight() {
		const tableRect = this.tableRect;
		if ( !( tableRect instanceof DOMRect ) ) {
			return void 0;
		}
		return tableRect.height;
	}

	set headClientHeight( headerHeightInPixels ) {
		if ( !Validator.isPositiveInteger( headerHeightInPixels ) ) {
			return;
		}
		this.setCssVariable( HEADER_HEIGHT_CSS_VARIABLE, `${ headerHeightInPixels }px` );
		const tableHeight = this.tableHeight;
		if ( !Validator.isPositiveNumber( tableHeight ) ) {
			return;
		}
		let horizontalScrollbarHeight = this.horizontalScrollbarClientHeight;
		if ( !Validator.isPositiveNumber( horizontalScrollbarHeight, true ) ) {
			horizontalScrollbarHeight = 12; // default
		}
		const requiredBodyHeight = tableHeight - headerHeightInPixels - horizontalScrollbarHeight;
		if ( Validator.isFunctionPath( this.wdgBody, "wdgBody.setTableBodyHeight" ) ) {
			this.wdgBody.setTableBodyHeight( requiredBodyHeight, true );
		}
		this.horizontalScrollbarClientTop = headerHeightInPixels + requiredBodyHeight;
		// this.bodyClientHeight = requiredBodyHeight;
		// TODO notify server ??
	}

	get headClientHeight() {
		return Validator.isObject( this.wdgHead ) &&
			"headClientHeight" in this.wdgHead ?
			this.wdgHead.headClientHeight : void 0;
	}

	set bodyClientHeight( bodyHeightInPixels ) {
		if ( !Validator.isPositiveNumber( bodyHeightInPixels ) ) {
			// TODO warn
			return;
		}
		this.setCssVariable( BODY_HEIGHT_CSS_VARIABLE, `${ bodyHeightInPixels }px` );
	}

	get bodyClientHeight() {
		return Validator.isObject( this.wdgBody ) &&
			"bodyClientHeight" in this.wdgBody ?
			this.wdgBody.bodyClientHeight : void 0;
	}

	get tableRect() {
		if ( !( this.parElm instanceof HTMLElement ) ) {
			return void 0;
		}
		return this.parElm.getBoundingClientRect();
	}

	get tableEndX() {
		const tableRect = this.tableRect;
		if ( !( tableRect instanceof DOMRect ) ) {
			return void 0;
		}
		return tableRect.x + tableRect.width;
	}

	get hasDomFocus() {
		if ( !this.isRendered ) {
			return false;
		}
		const activeElement = window.document.activeElement;
		if ( !( activeElement instanceof HTMLElement ) ) {
			return false;
		}
		return this.parElm === activeElement || this.parElm.contains( activeElement );
	}

	/**
	 * @returns {Boolean} the current visibility of the vertical scrollbar
	 */
	get vScbVis() {
		return this._vScbVis;
	}

	makeSureTableIsFocused() {
		if ( this.hasDomFocus ) {
			return true;
		}
		return this.focus();
	}

	setCssVariables() {
		const parentElement = this.parentElement;
		if ( !( parentElement instanceof HTMLElement ) ) {
			return false;
		}
		const customWidgetData = this.customWidgetData;
		if ( !Validator.isObject( customWidgetData ) ) {
			return false;
		}
		this.setColorCssVariables( parentElement, customWidgetData );
		this.setLengthCssVariables( parentElement, customWidgetData );
		this.setPyjamaColorProperties( customWidgetData );
		return true;
	}

	setCssVariable( cssVariableName, cssVariableValue ) {
		if ( !Validator.isString( cssVariableName ) ) {
			return false;
		}
		const pe = this.parentElement;
		if ( pe instanceof HTMLElement ) {
			pe.style.setProperty( cssVariableName, cssVariableValue );
			return true;
		} else {
			return false;
		}
	}

	setColorCssVariables( element, widgetData ) {
		if ( !Validator.isObject( widgetData ) ||
			!( element instanceof HTMLElement ) ) {
			return false;
		}
		COLOR_PROPERTIES_TO_CSS_VARS.forEach( ( cssVariable, property ) => {
			const color = XtwUtils.colorArrayToRgba( widgetData[ property ] );
			if ( !Validator.isString( color ) ) {
				return;
			}
			element.style.setProperty( cssVariable, color );
		} );
		delete this.setColorCssVariables;
		return true;
	}

	setLengthCssVariables( element, widgetData ) {
		if ( !Validator.isObject( widgetData ) ||
			!( element instanceof HTMLElement ) ) {
			return false;
		}
		LENGTH_PROPERTIES_TO_CSS_VARS.forEach( ( cssVariable, property ) => {
			const numericValue = Number( widgetData[ property ] );
			if ( !Validator.isPositiveInteger( numericValue ) ) {
				return;
			}
			element.style.setProperty( cssVariable, `${ numericValue }px` );
		} );
		delete this.setLengthCssVariables;
		return true;
	}

	setPyjamaColorProperties( widgetData ) {
		if ( !Validator.isObject( widgetData ) ) {
			return false;
		}
		const mainPyjamaColor = Color.fromRgba( widgetData.pyj );
		if ( !( mainPyjamaColor instanceof Color ) ) {
			return false;
		}
		Object.defineProperty( this, "cssProperties", {
			value: {},
			configurable: false,
			writable: false
		} );
		Object.defineProperty( this.cssProperties, "mainPyjamaColor", {
			value: mainPyjamaColor,
			configurable: false,
			writable: false
		} );
		Object.defineProperty( this.cssProperties, "secondPyjamaColor", {
			value: new Color( {} ),
			configurable: false,
			writable: false
		} );
		this.adjustColumnsDataColors();
		delete this.setPyjamaColorProperties;
		return true;
	}

	changeToRowTemplateClass() {
		if ( !Validator.isObject( this.wdgSlv ) || !( this.wdgSlv._element instanceof HTMLElement ) ) {
			return false;
		}
		this.wdgSlv._element.classList.add( "row-template" );
		this.wdgSlv._element.classList.remove( "table" );
		this.wdgSlv._element.style.height = `${ this.bodyClientHeight }px`;
		this.wdgSlv._element.style.top = "0px";
		return true;
	}

	changeToTableClass() {
		if ( !Validator.isObject( this.wdgSlv ) || !( this.wdgSlv._element instanceof HTMLElement ) ) {
			return false;
		}
		this.wdgSlv._element.classList.add( "table" );
		this.wdgSlv._element.classList.remove( "row-template" );
		[ "top", "height" ].forEach( styleProperty => {
			HtmHelper.removeStyleProperty( this.wdgSlv._element, styleProperty );
		} );
		return true;
	}

	get horizontalScrollbarClientHeight() {
		if ( !Validator.isObject( this.wdgSlh ) || !( this.wdgSlh._element instanceof HTMLElement ) ) {
			return false;
		}
		const horizontalScrollbarClientRect = this.wdgSlh._element.getBoundingClientRect();
		return horizontalScrollbarClientRect.height;
	}

	set horizontalScrollbarClientHeight( heightInPixels ) {
		if ( !Validator.isPositiveNumber( heightInPixels, true ) || !Validator.isObject( this.wdgSlh ) || !( this.wdgSlh._element instanceof HTMLElement ) ) {
			return;
		}
		this.wdgSlh._element.style.height = `${ heightInPixels }px`;
	}

	set horizontalScrollbarClientTop( valueInPixels ) {
		if ( !Validator.isPositiveNumber( valueInPixels, true ) || !Validator.isObject( this.wdgSlh ) || !( this.wdgSlh._element instanceof HTMLElement ) ) {
			return;
		}
		this.wdgSlh._element.style.top = `${ valueInPixels }px`;
	}

	forEachColumn( columnCallback ) {
		if ( !Validator.isObject( this.wdgHead ) || !Validator.is( this.wdgHead.columns, "ObjReg" ) || !Validator.isMap( this.wdgHead.columns._objReg ) ) {
			return false;
		}
		let allCallbacksSuccessful = true;
		for ( let column of [ ...this.wdgHead.columns._objReg.values() ] ) {
			const callBackResult = columnCallback( column );
			if ( callBackResult == false ) {
				allCallbacksSuccessful = false;
			}
		}
		return allCallbacksSuccessful;
	}

	adjustColumnsDataColors() {
		const allColumnsDataColorsAdjusted = this.forEachColumn( column => {
			if ( !Validator.is( column, "XtwCol" ) ) {
				return false;
			}
			return column.adjustDataColors();
		} );
		delete this.adjustColumnsDataColors;
		return allColumnsDataColorsAdjusted;
	}

	/**
	 * called by the framework to destroy the widget
	 */
	doDestroy() {
		XtwMgr.getInst().rmvXtdTbl( this );
		// clean-up
		this._cleanup();
		delete this.colDrg;
		delete this.rowDrg;
		delete this.size;
		delete this.ready;
		delete this.parent;
		delete this.wdgSlv;
		delete this.wdgSlh;
		delete this.idwSlv;
		delete this.idwSlh;
		delete this.clrVtg;
		delete this.clrHzg;
		delete this.wdgHead;
		delete this.wdgBody;
		delete this.parElm;
		super.doDestroy();
	}

	/**
	 * returns the widget ID
	 * @returns {String} the widget ID
	 */
	getId() {
		return this.wdgId;
	}

	get customWidgetData() {
		if ( !Validator.isObject( this.parent ) ||
			!Validator.isFunction( this.parent.getData ) ) {
			return void 0;
		}
		const customData = this.parent.getData( "pisasales.CSTPRP.CWD" );
		return Validator.isObject( customData ) ? customData : void 0;
	}

	get parentElement() {
		if ( this.parElm instanceof HTMLElement ) {
			return this.parElm;
		}
		if ( !Validator.isObjectPath( this.parent, "parent.$el" ) ||
			!Validator.isFunction( this.parent.$el.get ) ) {
			return void 0;
		}
		const element = this.parent.$el.get( 0 );
		if ( !( element instanceof HTMLElement ) ) {
			return void 0;
		}
		this.parElm = element;
		return element;
	}

	/**
	 * called internally after the widget has become fully initialized and rendered
	 */
	onReady() {
		this.ready = true;
		const dbg = this._psa.isDbgMode();
		this.setCssVariables();
		this.setupVerticalScrollbar();
		this.setupHorizontalScrollbar();
		const self = this;
		const de = this.parent.$el.get( 0 );
		if ( de ) {
			this.parElm = de;
			// hook in key event handler
			de._keyEvtHdl = this;
			// set event transfer handler
			new ExternalEventsTransferManager( this, this.parElm );
			// set debug markers
			if ( dbg && ( this.parElm instanceof HTMLElement ) &&
				( this.parElm.dataset instanceof DOMStringMap ) ) {
				this.parElm.dataset.class = "XtwTbl parent element";
				this.parElm.dataset.idstr = this.wdgIdStr;
			}
			// add event listeners
			const self = this;
			const passive = this._psa.canPassiveListeners;
			const opts = passive ? { passive: true } : false;
			de.addEventListener( 'mouseleave', (e) => {
				self._onCaptureMouseLeave(e, de);
			}, { capture: true, passive: true });
			de.addEventListener( 'mouseleave', ( e ) => {
				self._onMouseLeave( e );
			}, false );
			de.addEventListener( 'mousemove', ( e ) => {
				self._onMouseMove( e );
			}, false );
			de.addEventListener( 'mouseup', ( e ) => {
				self._onMouseUp( e );
			}, false );
			de.addEventListener( 'wheel', ( e ) => {
				self._onMouseWheel( e, passive );
			}, opts );
			de.addEventListener( 'click', ( e ) => {
				self._onClick( e );
			}, false );
			de.addEventListener( 'keyup', ( e ) => {
				self._onKeyUp( e );
			}, false );
			de.addEventListener( 'keydown', (e) => {
				self._onCaptureKeyDown(e);
			}, { capture: true, passive: true });
			de.addEventListener( 'keydown', (e) => {
				self._onKeyDown( e );
			}, false );
			// create column drag widget
			const cdw = document.createElement( 'div' );
			if ( dbg && ( cdw instanceof HTMLElement ) && ( cdw.dataset instanceof DOMStringMap ) ) {
				cdw.dataset.class = "column drag widget";
			}
			cdw.style.position = 'absolute';
			cdw.style.left = 0;
			cdw.style.top = 0;
			cdw.style.width = '1px';
			cdw.style.height = 'inherit';
			cdw.style.borderLeft = '1px solid #000';
			cdw.style.zIndex = 1000000;
			cdw.style.display = 'none';
			this.cdrElm = cdw;
			de.appendChild( cdw );
			// create row drag widget
			this.createRowDragWidgetElement( dbg );
			de.appendChild( this.rdrElm );
		}
	}

	/**
	 * called by the framework in rendering phase
	 */
	onRender() {
		if ( this.parent ) {
			rap.off( "render", this.onRender ); // just once!
			this.onReady();
			this.layout();
		}
	}

	/**
	 * called by the framework if the widget has been resized
	 */
	layout() {
		if ( this.ready ) {
			// all we have to do is to inform the server side if the size as been changed
			const area = this.parent.getClientArea();
			const wdt = area[ 2 ] || 0;
			const hgt = area[ 3 ] || 0;
			this._updSize( wdt, hgt );
		}
	}

	/**
	 * sets the row template descriptor
	 * @param {Object} args row template descriptor
	 */
	setRowTpl( args ) {
		if ( ( typeof args === 'object' ) && !!args.rowTpl ) {
			this.rowTpl = args;
		} else {
			this.rowTpl = null;
		}
	}

	/**
	 * returns the row template descriptor
	 * @returns {Object} the row template descriptor or null
	 */
	getRowTpl() {
		return this.rowTpl;
	}

	/**
	 * sets the "RAP" focus flag
	 * @param {Boolean} rf the "RAP" focus flag
	 */
	setRapFocus( rf ) {
		this.rapFocus = !!rf;
		if ( this.wdgBody ) {
			this.wdgBody.setRapFocus( this.rapFocus );
		}
	}

	focus() {
		const body = this.wdgBody;
		if ( body && body.alive ) {
			return body.updateFocusedUI( true );
		} else {
			return false;
		}
	}

	/**
	 * returns the current "RAP focus" state
	 * @returns {Boolean} true if the table has the focus; false otherwise
	 */
	get isRapFocus() {
		return this.rapFocus;
	}

	/**
	 * initializes the view mode
	 * @param {Object} args arguments
	 */
	iniViewMode( args ) {
		if ( this.rowTpl ) {
			// only if a row template is available!
			const rtp = !!args.rtp;
			this.rtpTgl = !!args.tgl;
			if ( this.rtpMode !== rtp ) {
				this.rtpMode = rtp;
				if ( this.wdgBody ) {
					this.wdgBody.iniViewMode( this.rtpMode, this.rtpTgl );
				}
			}
		}
	}

	/**
	 * sets the header widget
	 * @param {XtwHead} xth the header widget
	 */
	setHeadWdg( xth ) {
		this.wdgHead = xth;
		if ( this.wdgBody ) {
			this.wdgBody.setTblHead( this.wdgHead );
			this.wdgHead.setTblBody( this.wdgBody );
		}
	}

	/**
	 * sets the body widget
	 * @param {XtwBody} xtb the body widget
	 */
	setBodyWdg( xtb ) {
		this.wdgBody = xtb;
		if ( this.wdgHead ) {
			this.wdgBody.setTblHead( this.wdgHead );
			this.wdgHead.setTblBody( this.wdgBody );
			if ( this.rowTpl ) {
				const args = { rtp: this.rtpMode, tgl: this.rtpTgl };
				this.rtpMode = !this.rtpMode; // force iniViewMode() to update the body widget
				this.iniViewMode( args );
			}
		}
		this.wdgBody.setRapFocus(this.rapFocus);
	}

	/**
	 * sets the widths of fixed and dynamic parts
	 * @param {Number} fxw width of fixed part
	 * @param {Number} dnw width of dynamic part
	 */
	setPartWdt( fxw, dnw ) {
		if ( this.wdgBody ) {
			this.wdgBody.setPartWdt( fxw, dnw );
		}
	}

	/**
	 * @returns {Boolean} true if a column dragging operation is active; false otherwise
	 */
	isColumnDragging() {
		return !!this.colDrg;
	}

	/**
	 * initiates a column drag operation
	 * @param {MouseEvent} e the mouse event
	 * @param {XtwCol} col the affected column
	 * @param {HTMLElement} elm the "hot" element
	 * @param {Boolean} full "full column" drag
	 */
	onColumnDrag( e, col, elm, full ) {
		// initialize column drag
		this._cdrStart( e, col, elm, full );
	}

	onHeightAdjustmentDivMouseDown( domEvent, xRowItem ) {
		this._rdrStart( domEvent, xRowItem, xRowItem.element );
		return true;
	}

	/**
	 * initiates a row height adjustment operation
	 * @param {MouseEvent} domEvent the mouse event
	 * @param {XRowItem} xRowItem the affected row
	 * @param {HTMLElement} element the "hot" element
	 */
	onRowDrag( domEvent, xRowItem, element ) {
		// initialize row drag
		this._rdrStart( element, xRowItem, element );
	}

	/**
	 * starts a row drag operation
	 * @param {MouseEvent} domEvent the mouse event
	 * @param {XRowItem} row the affected row
	 * @param {HTMLElement} element the "hot" element
	 */
	_rdrStart( domEvent, row, element ) {
		this._dragStop();
		const rd = {};
		const yp = this._getEffPos( domEvent ).y;
		rd.pos = yp;
		rd.row = row;
		rd.elm = element;
		this.rowDrg = rd;
		this.rdrElm.style.top = yp + 'px';
		this.rdrElm.style.display = '';
		this.parElm.style.cursor = 'row-resize';
	}

	/**
	 * strops a row drag operation
	 */
	_rdrStop() {
		if ( !Validator.isObject( this.rowDrg ) ) {
			return false;
		}
		delete this.rowDrg;
		this.rowDrg = void 0;
		if ( this.rdrElm instanceof HTMLElement ) {
			this.rdrElm.style.display = 'none';
		}
		if ( this.parElm instanceof HTMLElement ) {
			this.parElm.style.cursor = '';
		}
		return true;
	}

	onRowDragMouseUp( domEvent ) {
		if ( !Validator.isObjectPath( this.rowDrg, "rowDrg.row" ) ||
			!Validator.isPositiveNumber( this.rowDrg.pos ) ) {
			this._dragStop(); // stop!
			return false;
		}
		// at this point we have to update all affected elements and to notify the
		// server side about changed size requirements
		const xRowItem = this.rowDrg.row;
		if ( xRowItem.isGroupHead ) {
			this._dragStop(); // stop!
			return false;
		}
		const xRowItemHeight = Number( xRowItem.height );
		if ( !Validator.isPositiveNumber( xRowItemHeight ) ) {
			this._dragStop(); // stop!
			return false;
		}
		const xRowItemClientHeight = Number( xRowItem.clientHeight );
		const eventEffectivePositionY = Number( this._getEffPos( domEvent ).y );
		if ( !Validator.isPositiveNumber( eventEffectivePositionY ) ) {
			this._dragStop(); // stop!
			return false;
		}
		const rowDragPosition = Number( this.rowDrg.pos );
		if ( !Validator.isPositiveNumber( rowDragPosition ) ) {
			this._dragStop(); // stop!
			return false;
		}
		const heightDifference = eventEffectivePositionY - rowDragPosition;
		const resultantHeight = Math.max( xRowItemHeight + heightDifference, MINIMAL_ROW_HEIGHT );
		this._dragStop(); // stop!
		// call the "official" method to notify all parts that need to know this
		return this.wdgBody.onRowHeight( resultantHeight );
	}

	onRowDragMouseMove( domEvent ) {
		this.rdrElm.style.top = this._getEffPos( domEvent ).y + 'px';
	}

	cleanupRowDragElement() {
		const rdw = this.rdrElm;
		delete this.rdrElm;
		this._psa.rmvDomElm( rdw );
	}

	createRowDragWidgetElement( ) {
		const rowDragWidgetElement = window.document.createElement( "div" );
		rowDragWidgetElement.dataset.class = "row drag widget";
		rowDragWidgetElement.style.position = 'absolute';
		rowDragWidgetElement.style.left = 0;
		rowDragWidgetElement.style.top = 0;
		rowDragWidgetElement.style.width = 'inherit';
		rowDragWidgetElement.style.height = '1px';
		rowDragWidgetElement.style.borderTop = '1px solid #000';
		rowDragWidgetElement.style.zIndex = 1000000;
		rowDragWidgetElement.style.display = 'none';
		this.rdrElm = rowDragWidgetElement;
	}

	/**
	 * performs cleanup on destruction
	 */
	_cleanup() {
		this._autoScrollStop();
		if ( this.parElm instanceof HTMLElement ) {
			this.parElm._keyEvtHdl = null;
		}
		if ( this.cdrElm ) {
			const cdw = this.cdrElm;
			delete this.cdrElm;
			this._psa.rmvDomElm( cdw );
		}
		if ( this.rdrElm ) {
			this.cleanupRowDragElement();
		}
		if ( this.wdgSlv ) {
			const slv = this.wdgSlv;
			this.wdgSlv = null;
			slv.removeEventListener( 'selectionChanged', this._onVScroll, this );
		}
		if ( this.wdgSlh ) {
			const slh = this.wdgSlh;
			this.wdgSlh = null;
			slh.removeEventListener( 'selectionChanged', this._onHScroll, this );
		}
	}

	/**
	 * sends a notification to the web server
	 * @param {String} code notification code
	 * @param {Object} par notification parameters
	 */
	_nfySrv( code, par ) {
		if ( this.ready ) {
			const tms = Date.now();
			const param = {};
			param.cod = code;
			param.par = par;
			param.tms = tms;
			rap.getRemoteObject( this ).notify( "PSA_XTD_TBL_NFY", param );
		}
	}

	/**
	 * updates the cached size values and notifies the server side if the size has been changed
	 * @param {Number} cx new width in pixels
	 * @param {Number} cy new height in pixels
	 */
	_updSize( cx, cy ) {
		const widthChanged = this.size.cx !== cx;
		const chn = widthChanged || ( this.size.cy != cy );
		if ( widthChanged ) {
			this.setAutoFitModusColumnsWidths( cx );
		}
		if ( chn ) {
			this.size.cx = cx;
			this.size.cy = cy;
			this._nfySrv( 'resize', this.size );
		}
	}

	/**
	 * handles "mouseleave" events in capture phase
	 * @param {MouseEvent} evt the mouse event
	 * @param {HTMLElement} de the DOM element
	 */
	_onCaptureMouseLeave(evt, de) {
		if ( (evt.target === de) && this.alive ) {
			this._autoScrollStop();
		}		
	}

	/**
	 * handle keyboard events in capture phase
	 * @param {KeyboardEvent} e the keyboard event
	 */
	_onCaptureKeyDown(e) {
		if ( e.key === 'Escape' ) {
			this._autoScrollStop();
		}
	}

	_onMouseLeave( e ) {
		this._autoScrollStop();
		if ( this.colDrg ) {
			// stop any column drag
			this._cdrStop();
		}
		if ( this.rowDrg ) {
			// stop any row drag
			this._rdrStop();
		}
		if ( Validator.is( this.wdgHead, "XtwHead" ) ) {
			this.wdgHead.onTblMouseLeave( e );
		}
	}

	_onMouseMove( e ) {
		if ( this.colDrg ) {
			this.cdrElm.style.left = this._getEffPos( e ).x + 'px';
		}
		if ( this.rowDrg ) {
			this.onRowDragMouseMove( e );
		}
		if ( Validator.is( this.wdgHead, "XtwHead" ) ) {
			this.wdgHead.onTblMouseMove( e );
		}
	}

	_onMouseUp( e ) {
		if ( this.colDrg ) {
			const xp = this._getEffPos( e ).x;
			const dw = xp - this.colDrg.pos;
			// at this point we have to update all columns and to notify the server side about changed size requirements
			const col = this.colDrg.col;
			const wdt = Math.max( col.width + dw, 4 );
			// stop!
			this._dragStop();
			// call the "official" method to notify all parts that need to know this
			this.wdgHead.onColumnWidth( col, wdt, e )
		}
		if ( this.rowDrg ) {
			this.onRowDragMouseUp( e );
		}
		if ( this.wdgHead instanceof XtwHead ) {
			this.wdgHead.onTblMouseUp( e );
		}
	}

	/**
	 * triggers a vertical scroll operation
	 * @param {Number} dist scrolling distance
	 * @param {Boolean} up direction flag
	 * @param {Boolean} force "force" flag
	 * @returns {Boolean} true if scrolling operation was initiated; false otherwise
	 */
	triggerVScroll(dist, up, force = false) {
		return this._scrollBy(dist, up, true, !!force);
	}

	/**
	 * sets a new vertical scroll positions
	 * @param {Number} pos new vertical scroll position
	 * @returns {Boolean} true if a scrolling operation was initiated; false otherwise
	 */
	setVScrollPos(pos) {
		return this._setScrollPos(pos, true, false);
	}

	/**
	 * sets a new horizontal scroll position
	 * @param {Number} pos new horizontal scroll position
	 * @returns {Boolean} true if a scrolling operation was initiated; false otherwise
	 */
	setHScrollPos(pos) {
		return this._setScrollPos(pos, false, false);
	}

	/**
	 * called by the web server to force a scrolling position
	 * @param {*} args parameters
	 */
	forceScrollPos(args) {
		if ( this.alive ) {
			const vert = !!args.vert;
			const pos = args.pos;
			if ( Validator.isPositiveInteger(pos, true) ) {
				const body = this.wdgBody;
				if ( body instanceof XtwBody ) {
					body.forceLastScrollPos(vert, pos);
				}
				this._setScrollPos(pos, vert, true);
			}
		}
	}

	/**
	 * sets a new scroll position
	 * @param {Number} pos new scroll position
	 * @param {Boolean} vertical false: horizontal scroll operation; true: vertical scroll operation
	 * @param {Boolean} force flag whether to force a scroll update
	 * @returns {Boolean} true if a scroll operation was initiated; false otherwise
	 */
	_setScrollPos(pos, vertical, force = false) {
		this._dragStop();
		const sb = vertical ? this.wdgSlv : this.wdgSlh;
		if ( Validator.isInteger(pos) && sb && this.wdgBody ) {
			const mx = sb.getMaximum();
			const mn = sb.getMinimum();
			const np = Math.min(Math.max(pos, mn), mx);
			if ( force ) {
				sb._selection = np + 1;
			}
			const sp = sb._selection;
			if ( sp !== np ) {
				if ( this.isDebugEnabled() ) {
					this.log(`SETPOS: Setting ${vertical ? 'vertical' : 'horizontal'} scrolling position to ${np}.`);
				}
				sb.setSelection(np);
				return true;
			}
		}
		return false;
	}

	/**
	 * scrolls the view by a relative distance
	 * @param {Number} dist scrolling distance
	 * @param {Boolean} up direction flag; false horizontal, true: vertical
	 * @param {Boolean} vertical false: horizontal scroll operation; true: vertical scroll operation
	 * @param {Boolean} force "force" flag
	 * @returns {Boolean} true if a scroll operation was initiated; false otherwise
	 */
	_scrollBy(dist, up, vertical, force) {
		this._dragStop();
		const sb = vertical ? this.wdgSlv : this.wdgSlh;
		if ( Validator.isInteger(dist) && sb && this.wdgBody ) {
			if ( dist !== 0 ) {
				if ( vertical ) {
					if ( !this.wdgBody.canScrollBy(dist, up) ) {
						// not possible!
						this.trace('Vertical scrolling forbidden!');
						return false;
					}
				}
				const mx = sb.getMaximum();
				const mn = sb.getMinimum();
				const tb = sb.getThumb();
				const sp = sb._selection;
				const np = up ? Math.max( sp - dist, mn ) : Math.min( sp + dist, mx - tb );
				if ( force || (np !== sp) ) {
					if ( this.isDebugEnabled() ) {
						this.log(`SCROLLBY: Setting ${vertical ? 'vertical' : 'horizontal'} scrolling position to ${np}.`);
					}
					if ( force ) {
						sb._selection = np + 1;
					}
					sb.setSelection( np );
					return true;
				}
			}
		}
		return false;
	}

	/**
	 * @returns {Boolean} true if an auto scroll timer is available; false otherwise
	 */
	_hasAutoScrollTimer() {
		return !!this._ascTimer;
	}

	/**
	 * checks whether an auto scroll operation is possible
	 * @param {*} sb scrollbar widget
	 * @param {Number} pos scrolling position to be checked
	 * @param {Boolean} up scrolling direction; false: downwards; true: upwards
	 * @returns {Boolean} true if an auto scroll operation is possible; false otherwise
	 */
	_isAutoScrollPossible(sb, pos, up) {
		if ( !this.alive ) {
			return false;
		}
		const sp = pos;//sb._selection;
		const mx = sb.getMaximum();
		const mn = sb.getMinimum();
		const tb = sb.getThumb();
		const possible = up ? (sp > mn) : (sp < (mx - tb));
		if ( this.isTraceEnabled() ) {
			this.trace(`XTW auto scroll possible sp=${sp}, mn=${mn}, mx=${mx}, tb=${tb} --> ${possible}`);
		}
		return possible;
	}

	/**
	 * starts an auto scroll process
	 * @param {Boolean} up scrolling direction; false: downwards; true: upwards
	 */
	_autoScrollStart(up) {
		if ( this.alive && this.vScbVis && !this._hasAutoScrollTimer() ) {
			const sb = this.wdgSlv;
			const dir = !!up;
			if ( sb && this._isAutoScrollPossible(sb, sb._selection, dir) ) {
				const self = this;
				const ast = setInterval(() => {
					self._doAutoScroll(sb, dir);
				}, AUTO_SCROLL_INTERVAL);
				this._ascTimer = ast;
				this.trace('Auto scrolling started.');
			}
		}
	}

	/**
	 * stops any automatic vertical scrolling
	 */
	_autoScrollStop() {
		if ( this._hasAutoScrollTimer() ) {
			const ast = this._ascTimer;
			this._ascTimer = null;
			clearInterval(ast);
			this.trace('Auto scrolling stopped.');
		}
	}

	/**
	 * performs an aut scrolling step
	 * @param {*} sb scrollbar widget
	 * @param {Boolean} up scrolling direction; false: downwards; true: upwards
	 */
	_doAutoScroll(sb, up) {
		if ( this.alive ) {
			const np = sb._selection + (up ? -1 : 1);
			if ( this.isTraceEnabled() ) {
				this.trace(`Auto scrolling to position ${np}.`);
			}
			if ( !this._isAutoScrollPossible(sb, np, up) ) {
				this._autoScrollStop();
			}
			sb.setSelection(np);
		} else {
			this._autoScrollStop();
		}
	}


	_onMouseWheel( e, passive ) {
		this._dragStop();
		if ( this.wdgSlv && this.wdgBody ) {
			if ( e.deltaY ) {
				// we don't care about the mode, we just want the direction
				const up = e.deltaY < 0;
				const dist = 3; // we use a fixed "wheel" distance of 3 items - was: //this.wdgBody.getPageScrollDist( up, true );
				if ( dist !== 0 ) {
					this.triggerVScroll(dist, up, false);
					if ( !passive ) {
						e.preventDefault();
						e.stopPropagation();
					}
				}
			}
		}
		if ( Validator.is( this.wdgHead, "XtwHead" ) ) {
			this.wdgHead.onTblMouseWheel( e );
		}
		if ( Validator.is( this.wdgBody, "XtwBody" ) ) {
			this.wdgBody.onTblMouseWheel( e );
		}
	}

	/**
	 * handles mouse clicks somewhere in the table
	 * @param {MouseEvent} e the mouse event
	 */
	_onClick( e ) {
		const tgt = e.target;
		let done = false;
		if ( tgt && this._psa.isStr( tgt.__psanfo ) ) {
			// that's a marked element!
			const marker = tgt.__psanfo;
			const pc = marker.indexOf( ':' );
			if ( pc !== -1 ) {
				const pa = marker.indexOf( '@' );
				const ns = ( pa !== -1 ) ? marker.substring( pc + 1, pa ) : marker.substring( pc + 1 );
				const idc = parseInt( ns, 10 );
				if ( !isNaN( idc ) && ( idc !== -1 ) ) {
					// a valid column ID that is *not* the "select" column
					const idr = ( pa !== -1 ) ? parseInt( marker.substring( pa + 1 ) ) : 0;
					if ( !isNaN( idr ) ) {
						const par = { idc: idc, idr: idr };
						this._nfySrv( 'partClick', par );
						done = true;
					}
				}
			}
		}
		if ( !done ) {
			// we got a mouse click somewhere else; just trigger the focus trackers
			const par = { idc: 0, idr: 0 };
			this._nfySrv( 'partClick', par );
		}
	}

	/**
	 * forwards a keyboard event to the table body
	 * @param {KeyboardEvent} evt the keyboard event
	 * @param {Boolean} press flag whether the key is pressed ('keydown') or released ('keyup')
     * @returns {Boolean} true if the keyboard event was handled; false otherwise
	 */
	_onBodyKeyEvent(evt, press) {
		// forward to body
		const body = this.wdgBody;
		if ( (body instanceof XtwBody) && body.alive ) {
			return body.onKeyEvent(evt, press);
		}
		return false;
	}

	_onKeyDown( evt ) {
		if ( this.handleCopyTooltipSumRequest( evt ) ) {
			return;
		}
		// forward to body
		this._onBodyKeyEvent(evt, true);
	}

	_onKeyUp( evt ) {
		if ( this.handleRefreshAllRequest( evt ) ) {
			return;
		}
		// forward to body
		this._onBodyKeyEvent(evt, false);
	}

	/**
	 * handles keyboard events that are processed by RAP - see KeyHdl.hdlKey()
	 * @param {KeyboardEvent} ke the keyboard event
	 * @param {HTMLElement} tgt the real target element
     * @returns {Boolean} true if the keyboard event was handled; false otherwise
	 */
	hdlKeyEvt(ke, tgt) {
		if ( this.alive && this.wdgBody && (this.rapFocus || this.hasDomFocus) ) {
			// forward to body widget
			return this._onBodyKeyEvent(ke, ke.type === 'keydown');
		}
		return false;
	}


	handleRefreshAllRequest( evt ) {
		if ( !( evt instanceof KeyboardEvent ) || !evt.altKey ||
			!XtwUtils.isCommandKeyPressed( evt ) ||
			![ "R", "r", "KeyR" ].find( key =>
				XtwUtils.keyIs( evt, key ) ) ) {
			return false;
		}
		this._nfySrv( "refreshAll", {} );
		return true;
	}

	handleCopyTooltipSumRequest( domEvent ) {
		if ( !DomEventHelper.keyIs( domEvent, "s" ) || !DomEventHelper.isCtrlEvent( domEvent ) || !domEvent.altKey ) {
			return false;
		}
		const column = this.activeMouseoverTooltipSumColumn;
		if ( !Validator.isObject( column ) || !( "tooltipSum" in column ) ) {
			return false;
		}
		if ( !column.handleCopyTooltipSumRequest() ) {
			return false;
		}
		DomEventHelper.stopIf( domEvent );
		return true;
	}

	removeAllColumnTooltips() {
		const head = this.wdgHead;
		if ( !Validator.isObject( head ) || !( "allColumns" in head ) ) {
			return false;
		}
		const columns = head.allColumns;
		if ( !Validator.isArray( columns ) ) {
			return false;
		}
		for ( let column of columns ) {
			if ( Validator.isFunctionPath( column, "column.onMouseLeave" ) ) {
				column.onMouseLeave();
			}
		}
		return true;
	}

	/**
	 * @return {Boolean} whether or not the current template is a "row template";
	 * "true" if the current template is a "row template", "false" otherwise
	 */
	get isRowTpl() {
		if ( !Validator.is( this.wdgBody, "XtwBody" ) ||
			!( "isRowTpl" in this.wdgBody ) ) {
			return false;
		}
		return this.wdgBody.isRowTpl;
	}

	/**
	 * @return {Boolean} whether or not the "row template" view/display is
	 * supported
	 */
	get hasRowTpl() {
		if ( !Validator.isObject( this.rowTpl ) ) {
			return false;
		}
		return !!this.rowTpl.rowTpl;
	}

	_getEffPos( e ) {
		let x = e.clientX;
		let y = e.clientY;
		if ( this.parElm ) {
			const r = this.parElm.getBoundingClientRect();
			x -= r.left;
			y -= r.top;
		}
		return { x: x, y: y };
	}

	/**
	 * stops all dragging operations
	 */
	_dragStop() {
		this._cdrStop();
		this._rdrStop();
	}

	/**
	 * starts a column drag operation
	 * @param {MouseEvent} e the mouse event
	 * @param {XtwCol} col the affected column
	 * @param {HTMLElement} elm the "hot" element
	 * @param {Boolean} full "full column" drag
	 */
	_cdrStart( e, col, elm, full ) {
		this._dragStop();
		const cd = {};
		const xp = this._getEffPos( e ).x;
		cd.pos = xp;
		cd.col = col;
		cd.elm = elm;
		cd.full = full;
		this.colDrg = cd;
		this.cdrElm.style.left = xp + 'px';
		this.cdrElm.style.display = '';
		this.parElm.style.cursor = 'col-resize';
	}

	/**
	 * stops a column drag operation
	 */
	_cdrStop() {
		if ( this.colDrg ) {
			delete this.colDrg;
			this.colDrg = null;
			if ( this.cdrElm ) {
				this.cdrElm.style.display = 'none';
			}
			if ( this.parElm ) {
				this.parElm.style.cursor = '';
			}
		}
	}

	resetColumnsDefaultProperties( orderedColumnsProperties ) {
		const headSuccessfullyReset = !Validator.is( this.wdgHead, "XtwHead" ) ? false :
			this.wdgHead.resetColumnsDefaultProperties( orderedColumnsProperties );
		this.autoFitColumns();
		if ( !headSuccessfullyReset && this.isTraceEnabled() ) {
			this.trace('Could not reset default columns properties on the table widget head <XtwHead>.');
		}
	}

	handleTransferredEvent( domEvent ) {
		if ( XtwUtils.isArrowUp( domEvent ) ||
			XtwUtils.isArrowDown( domEvent ) ) {
			if ( !Validator.is( this.wdgBody, "XtwBody" ) ||
				!Validator.isFunction( this.wdgBody.handleTransferredEvent ) ) {
				if ( this.isTraceEnabled() ) {
					this.trace(`The DOM arrow navigation event could not be` +
					` transferred from the table widget to the table body` +
					` widget, because the table body widget does not have a` +
					` method to handle transferred DOM events.`);
				}
				return false;
			}
			return this.wdgBody.handleTransferredEvent( domEvent )
		}
	}


	setupVerticalScrollbar() {
		if ( !Validator.isString( this.idwSlv ) ) {
			return false;
		}
		const verticalScrollbar = rwt.remote.ObjectRegistry.getObject( this.idwSlv );
		if ( !Validator.isObject( verticalScrollbar ) ) {
			return false;
		}
		this.wdgSlv = verticalScrollbar;
		const sbe = verticalScrollbar._element;
		if ( sbe instanceof HTMLElement ) {
			// mark scrollbar
			HtmHelper.markScrollbar(sbe);
		}
		this.setupVerticalScrollbarMouseUp( verticalScrollbar );
		verticalScrollbar.addEventListener( 'selectionChanged', this._onVScroll, this );
		if ( sbe instanceof HTMLElement ) {
			sbe.classList.add( "xtw-scrollbar", "vertical" );
			HtmHelper.removeStyleProperty(sbe, "top");
			HtmHelper.removeStyleProperty(sbe, "height");
			sbe.dataset.class = "vertical scrollbar"
		}
		// enforce minimal thumb size
		verticalScrollbar.setMinThumbSize(this.minimalThumbSize);
		// set special mouse move handling
		this._setupScrollBarMouseMove(verticalScrollbar);
		return true;
	}

	setupVerticalScrollbarMouseUp( verticalScrollbar ) {
		this._setupVerticalScrollbarMouseUp( verticalScrollbar );
		this._setupVerticalScrollbarThumbMouseUp( verticalScrollbar );
		this._setupVerticalScrollbarMouseUpThumb( verticalScrollbar );
	}

	/**
	 * called after a scrollbar click
	 */
	_afterSBClick() {
		const body = this.wdgBody;
		if ( body instanceof XtwBody ) {
			// call body widget
			body.afterSBClick();
		}
	}

	_setupVerticalScrollbarMouseUp( verticalScrollbar ) {
		const isValidScrollBarObject = ( scrollBarObject ) => {
			return Validator.isFunctionPath( scrollBarObject, "scrollBarObject._onMouseUp" );
		};
		if ( !isValidScrollBarObject( verticalScrollbar ) ) {
			verticalScrollbar = this.wdgSlv;
			if ( !isValidScrollBarObject( verticalScrollbar ) ) {
				return false;
			}
		}
		const self = this;
		const onMouseUp = ( event ) => {
			try {
				if ( self.onVerticalScrollbarMouseUp( event ) ) {
					return;
				}
				if ( Validator.isFunctionPath( verticalScrollbar, "verticalScrollbar._onOriginalMouseUp" ) ) {
					verticalScrollbar._onOriginalMouseUp( event );
				}
			} finally {
				self._afterSBClick();
			}
		};
		verticalScrollbar._onOriginalMouseUp = verticalScrollbar._onMouseUp;
		verticalScrollbar.removeEventListener( "mouseup", verticalScrollbar._onMouseUp, verticalScrollbar );
		verticalScrollbar.addEventListener( "mouseup", onMouseUp, verticalScrollbar );
		return true;
	}

	_setupVerticalScrollbarThumbMouseUp( verticalScrollbar ) {
		const isValidScrollBarObject = ( scrollBarObject ) => {
			return Validator.isObjectPath( scrollBarObject, "scrollBarObject._thumb" ) && Validator.isFunction( scrollBarObject._onThumbMouseUp );
		};
		if ( !isValidScrollBarObject( verticalScrollbar ) ) {
			verticalScrollbar = this.wdgSlv;
			if ( !isValidScrollBarObject( verticalScrollbar ) ) {
				return false;
			}
		}
		const self = this;
		const onThumbMouseUp = ( event ) => {
			try {
				if ( self.onVerticalScrollbarThumbMouseUp( event ) ) {
					return;
				}
				if ( Validator.isFunctionPath( verticalScrollbar, "verticalScrollbar._onOriginalThumbMouseUp" ) ) {
					verticalScrollbar._onOriginalThumbMouseUp( event );
				}
			} finally {
				self._afterSBClick();
			}
		};
		verticalScrollbar._onOriginalThumbMouseUp = verticalScrollbar._onThumbMouseUp;
		verticalScrollbar._thumb.removeEventListener( "mouseup", verticalScrollbar._onThumbMouseUp, verticalScrollbar );
		verticalScrollbar._thumb.addEventListener( "mouseup", onThumbMouseUp, verticalScrollbar );
		return true;
	}

	_setupVerticalScrollbarMouseUpThumb( verticalScrollbar ) {
		const isValidScrollBarObject = ( scrollBarObject ) => {
			return Validator.isFunctionPath( scrollBarObject, "scrollBarObject._thumb._onMouseUp" );
		};
		if ( !isValidScrollBarObject( verticalScrollbar ) ) {
			verticalScrollbar = this.wdgSlv;
			if ( !isValidScrollBarObject( verticalScrollbar ) ) {
				return false;
			}
		}
		const self = this;
		const onMouseUpThumb = ( event ) => {
			try {
				if ( self.onVerticalScrollbarMouseUpThumb( event ) ) {
					return;
				}
				if ( Validator.isFunctionPath( verticalScrollbar, "verticalScrollbar._thumb._onOriginalMouseUp" ) ) {
					verticalScrollbar._thumb._onOriginalMouseUp( event );
				}
			} finally {
				self._afterSBClick();
			}
		};
		verticalScrollbar._thumb._onOriginalMouseUp = verticalScrollbar._thumb._onMouseUp;
		verticalScrollbar._thumb.removeEventListener( "mouseup", verticalScrollbar._thumb._onMouseUp, verticalScrollbar._thumb );
		verticalScrollbar._thumb.addEventListener( "mouseup", onMouseUpThumb, verticalScrollbar._thumb );
		return true;
	}

	onVerticalScrollbarMouseUpThumb( event ) {
		return this.onVerticalScrollbarMouseUp( event );
	}

	onVerticalScrollbarThumbMouseUp( event ) {
		return this.onVerticalScrollbarMouseUp( event );
	}

	onVerticalScrollbarMouseUp( event ) {
		if ( !this.isVerticalScrollbarRedundant ) {
			return false;
		}
		if ( Validator.isObject( event ) ) {
			DomEventHelper.stopIf( event._valueDomEvent );
		}
		return true;
	}

	setupHorizontalScrollbar() {
		if ( !Validator.isString( this.idwSlh ) ) {
			return false;
		}
		const horizontalScrollbar = rwt.remote.ObjectRegistry.getObject( this.idwSlh );
		if ( !Validator.isObject( horizontalScrollbar ) ) {
			return false;
		}
		this.wdgSlh = horizontalScrollbar;
		horizontalScrollbar.addEventListener( 'selectionChanged', this._onHScroll, this );
		const sbe = horizontalScrollbar._element;
		if ( sbe instanceof HTMLElement ) {
			HtmHelper.markScrollbar(sbe);
			sbe.classList.add( "xtw-scrollbar", "horizontal" );
			sbe.dataset.class = "horizontal scrollbar"
		}
		// enforce a minimal thumb size
		horizontalScrollbar.setMinThumbSize(this.minimalThumbSize);
		// hook-in mouseup handling
		this._setupHorizontalScrollbarMouseUp(horizontalScrollbar);
		this._setupHorizontalScrollbarThumbMouseUp(horizontalScrollbar);
		this._setupHorizontalScrollbarMouseUpThumb(horizontalScrollbar);
		// set special mouse move handling
		this._setupScrollBarMouseMove(horizontalScrollbar);
		return true;
	}

	_setupHorizontalScrollbarMouseUp(hsb) {
		if ( hsb && (typeof hsb._onMouseUp === 'function') ) {
			const self = this;
			const onMouseUp = ( event ) => {
				try {
					if ( hsb && (typeof hsb._onOriginalMouseUp === 'function') ) {
						hsb._onOriginalMouseUp( event );
					}
				} finally {
					self._afterSBClick();
				}
			};
			hsb._onOriginalMouseUp = hsb._onMouseUp;
			hsb.removeEventListener( "mouseup", hsb._onMouseUp, hsb );
			hsb.addEventListener( "mouseup", onMouseUp, hsb );
		}
	}

	_setupHorizontalScrollbarThumbMouseUp( hsb ) {
		if ( hsb && hsb._thumb && (typeof hsb._onThumbMouseUp === 'function') ) {
			const self = this;
			const onThumbMouseUp = ( event ) => {
				try {
					if ( hsb && (typeof hsb._onOriginalThumbMouseUp === 'function') ) {
						hsb._onOriginalThumbMouseUp( event );
					}
				} finally {
					self._afterSBClick();
				}
			};
			hsb._onOriginalThumbMouseUp = hsb._onThumbMouseUp;
			hsb._thumb.removeEventListener( "mouseup", hsb._onThumbMouseUp, hsb );
			hsb._thumb.addEventListener( "mouseup", onThumbMouseUp, hsb );
		}
	}

	_setupHorizontalScrollbarMouseUpThumb( hsb ) {
		if ( hsb && hsb._thumb && (typeof hsb._thumb._onMouseUp === 'function') ) {
			const self = this;
			const onMouseUpThumb = ( event ) => {
				try {
					if ( hsb && hsb._thumb && (typeof hsb._thumb._onOriginalMouseUp === 'function') ) {
						hsb._thumb._onOriginalMouseUp( event );
					}
				} finally {
					self._afterSBClick();
				}
			};
			hsb._thumb._onOriginalMouseUp = hsb._thumb._onMouseUp;
			hsb._thumb.removeEventListener( "mouseup", hsb._thumb._onMouseUp, hsb._thumb );
			hsb._thumb.addEventListener( "mouseup", onMouseUpThumb, hsb._thumb );
		}
	}

	_onScrollbarMouseMove(scb, thumb, event) {
		this.trace(event);
		let stop = false;
		if ( thumb.getCapture() ) {
			const me = event._valueDomEvent;
			if ( me instanceof MouseEvent ) {
				if ( (me.buttons & 0x01) !== 0x01 ) {
					// mousemove event without pressed main button, but the thumb still captures mouse events
					stop = true;
				}
			}
		}
		if ( !stop ) {
			scb._orgOnThumbMouseMove(event);
		} else {
			scb._onThumbMouseUp(event);
			thumb.setCapture(false);
			scb._onMouseUp(event);
		}
	}

	_setupScrollBarMouseMove(scrollBar) {
		const self = this;
		self.log('Setting up special mouse move handling', scrollBar);
		if ( scrollBar ) {
			const scb = scrollBar;
			if ( scb._thumb && (typeof scb._onThumbMouseMove === 'function') ) {
				const thumb = scb._thumb;
				const org_tmm = scb._onThumbMouseMove;
				thumb.removeEventListener('mousemove', org_tmm, scb);
				scb._orgOnThumbMouseMove = org_tmm;
				const onPisaThumbMouseMove = (event) => {
					self._onScrollbarMouseMove(scb, thumb, event);
				};
				scb._onThumbMouseMove = onPisaThumbMouseMove;
				thumb.addEventListener('mousemove', scb._onThumbMouseMove, scb);
			}
		}
	}

	/**
	 * provides the minimal scrollbar's thumb size
	 * @returns {Number} the minimal scrollbar's thumb size
	 */
	get minimalThumbSize() {
		// we use the same property for both horizontal and vertical scrollbars
		return Math.max(Validator.isNumber(this.verticalSliderOriginalWidth) ? this.verticalSliderOriginalWidth : MIN_THUMB_SIZE, MIN_THUMB_SIZE);
	}

	_onVScroll() {
		if ( this.wdgSlv && this.wdgBody ) {
			// the body scrolls vertically, nobody else
			const sp = this.wdgSlv._selection;
			this.log(`Scrolling vertically to position ${sp}`);
			this.wdgBody.onVScroll( sp );
		}
	}

	/**
	 * stops any automatic vertical scrolling
	 */
	stopAutoScroll() {
		this._autoScrollStop();
	}

	/**
	 * starts an auto scroll process
	 * @param {Boolean} up scrolling direction; false: downwards; true: upwards
	 */
	startAutoScroll(up) {
		this._autoScrollStart(!!up);
	}

	get isVerticalScrollbarRedundant() {
		if ( !Validator.isObject( this.wdgSlv ) || !( this.wdgSlv._element instanceof HTMLElement ) ) {
			return false;
		}
		const verticalScrollbarRect = this.wdgSlv._element.getBoundingClientRect();
		if ( !( verticalScrollbarRect instanceof DOMRect ) || !Validator.isPositiveNumber( verticalScrollbarRect.height ) ) {
			return false;
		}
		let totalChildHeight = 0;
		for ( let element of [ ...this.wdgSlv._element.children ] ) {
			if ( !( element instanceof HTMLElement ) ) {
				continue;
			}
			const elementRect = element.getBoundingClientRect();
			if ( !( elementRect instanceof DOMRect ) || !Validator.isPositiveNumber( elementRect.height ) ) {
				continue;
			}
			totalChildHeight += elementRect.height;
		}
		const heightDifference = Math.abs(Math.trunc( verticalScrollbarRect.height - totalChildHeight ) );
		return (heightDifference < 4);
	}

	adjustVerticalScrollbarBasedOnHeight() {
		if ( !this.isVerticalScrollbarRedundant ) {
			return false;
		}
		this._nfySrv( "scrollbarVisibility", {
			vertical: true,
			visible: false
		} );
		return true;
	}

	_onHScroll() {
		if ( this.wdgSlh && this.wdgBody && this.wdgHead ) {
			if ( !this.isRowTpl &&
				"isHorizontalScrollingNecessary" in this.wdgHead &&
				!this.wdgHead.isHorizontalScrollingNecessary ) {
				// ONLY in the table/excel display/view and
				// NOT in the row template display/view
				if ( !this.horizontalScrollWidgetShown ) {
					// there is no horizontal scroll widget element so we're (hopefully)
					// fine
					return;
				}
				if ( Validator.isFunction( this.wdgHead.rptHdrWidth ) ) {
					// suggest to the server-side table widget that maybe we don't need
					// a horizontal scroll widget
					this.wdgHead.rptHdrWidth();
				}
				if ( this.isTraceEnabled() ) {
					this.trace(`Horizontal scrolling is not necessary` +
					` due to the table header element being wide enough to fit both` +
					` the fixed and dynamic containers for column header cells. The` +
					` client width of the table header is greater than or equal to` +
					` the sum of both containers' widths. If the horizontal scrolling` +
					` widget is still present, it is strongly recommended to remove it.`);
				}
				return;
			};
			// header and body scroll horizontally - the "dynamic" part
			const pos = this.wdgSlh._selection;
			this.trace( "Scrolled horizontally: " + pos );
			this.wdgHead.onHScroll( pos );
			this.wdgBody.onHScroll( pos );
		}
	}

	get horizontalScrollWidgetShown() {
		if ( !Validator.isObject( this.wdgSlh ) ||
			!( this.wdgSlh._element instanceof HTMLElement ) ) {
			return false;
		}
		const scrollWidgetElement = this.wdgSlh._element;
		if ( scrollWidgetElement.style.display === "none" ) {
			return false;
		}
		if ( scrollWidgetElement.style.height === "0px" ) {
			return false;
		}
		return true;
	}

	onScrollbarVisibilityChange( parameters ) {
		if ( !Validator.isObject( parameters ) ) {
			return false;
		}
		const vertical = !!parameters.vertical;
		const visible = !!parameters.visible;
		return vertical ? this.onVerticalScrollbarVisibilityChange(visible) : this.onHorizontalScrollbarVisibilityChange(visible);
	}

	onVerticalScrollbarVisibilityChange( visible ) {
		this.autoFitColumns();
		this._vScbVis = !!visible;
		return visible ? this.onVerticalScrollbarShown() : this.onVerticalScrollbarHidden();
	}

	onHorizontalScrollbarVisibilityChange( visible ) {
		return visible ? this.onHorizontalScrollbarShown() : this.onHorizontalScrollbarHidden();
	}


	onVerticalScrollbarShown() {
		let headScrollResult = true;
		if ( this.wdgHead instanceof XtwHead ) {
			headScrollResult = this.wdgHead.onVerticalScrollbarShown();
		}
		let bodyScrollResult = true;
		if ( this.wdgBody instanceof XtwBody ) {
			bodyScrollResult = this.wdgBody.onVerticalScrollbarShown();
		}
		return headScrollResult && bodyScrollResult;
	}

	onVerticalScrollbarHidden() {
		let headScrollResult = true;
		if ( this.wdgHead instanceof XtwHead ) {
			headScrollResult = this.wdgHead.onVerticalScrollbarHidden();
		}
		let bodyScrollResult = true;
		if ( this.wdgBody instanceof XtwBody ) {
			bodyScrollResult = this.wdgBody.onVerticalScrollbarHidden();
		}
		return headScrollResult && bodyScrollResult;
	}

	onHorizontalScrollbarShown() {
		let headScrollResult = true;
		if ( this.wdgHead instanceof XtwHead ) {
			headScrollResult = this.wdgHead.onHorizontalScrollbarShown();
		}
		let bodyScrollResult = true;
		if ( this.wdgBody instanceof XtwBody ) {
			bodyScrollResult = this.wdgBody.onHorizontalScrollbarShown();
		}
		return headScrollResult && bodyScrollResult;
	}

	onHorizontalScrollbarHidden() {
		let headScrollResult = true;
		if ( this.wdgHead instanceof XtwHead ) {
			headScrollResult = this.wdgHead.onHorizontalScrollbarHidden();
		}
		let bodyScrollResult = true;
		if ( this.wdgBody instanceof XtwBody ) {
			bodyScrollResult = this.wdgBody.onHorizontalScrollbarHidden();
		}
		return headScrollResult && bodyScrollResult;
	}


	/** register custom widget type */
	static register() {
		console.debug( 'Registering custom widget XtwTbl.' );
		rap.registerTypeHandler( 'psawidget.XtwTbl', {
			factory: function ( properties ) {
				return new XtwTbl( properties );
			},
			destructor: 'destroy',
			properties: [ 'rowTpl', 'rapFocus' ],
			methods: [ 'iniViewMode', 'resetColumnsDefaultProperties', 'onScrollbarVisibilityChange', 'forceScrollPos' ],
			events: [ 'PSA_XTD_TBL_NFY' ]
		} );
	}
}
