/**
 * EdgeButtons widget
 */

import LoggingBase from "../../base/loggingbase";
import HtmHelper from "../../utils/HtmHelper";
import Utils from "../../utils/Utils";
import Validator from "../../utils/Validator";

import { DEFAULT_WIDTH, DEFAULT_SIZE, EDGE_LEFT, EDGE_TOP, EDGE_RIGHT, EDGE_BOTTOM, isValidEdge } from "./EBConst";
import EdgeHost from "./EdgeHost";


export default class EdgeButtons extends LoggingBase {

    /**
     * constructs a new instance
     * @param {*} properties initialization properties
     */
    constructor(properties) {
        super('widgets.edgebuttons.EdgeButtons');
        this.ready = false;
		Utils.bindAll(this, [ "onRender" ]);
        const parent = rap.getObject(properties.parent);
        const cwd = parent.getData("pisasales.CSTPRP.CWD");
        this.parent = parent;
        const width = cwd.width || DEFAULT_WIDTH;
        const size = cwd.size || DEFAULT_SIZE;
        const active = !!cwd.active;
        this._active = active;
        this._suspended = false;
        this._hosts = [];
        this._initHosts(width, size, active);
		// activate "render" event
		rap.on('render', this.onRender);
    }

    /**
     * @returns {Boolean} the active state
     */
    get active() {
        return this._active;
    }

    /**
     * @returns {Boolean} the suspended state
     */
    get suspended() {
        return this._suspended;
    }

    /**
     * @returns {EdgeHost} the left button host
     */
    get hostLeft() {
        return this._hosts[EDGE_LEFT];
    }

    /**
     * @returns {EdgeHost} the top button host
     */
    get hostTop() {
        return this._hosts[EDGE_TOP];
    }

    /**
     * @returns {EdgeHost} the right button host
     */
    get hostRight() {
        return this._hosts[EDGE_RIGHT];
    }

    /**
     * @returns {EdgeHost} the bottom button host
     */
    get hostBottom() {
        return this._hosts[EDGE_BOTTOM];
    }

    /**
     * @inheritdoc
     * @override
     */
    destroy() {
        super.destroy();
    }

    /**
     * @inheritdoc
     * @override
     */
    doDestroy() {
        this.ready = false;
        this._active = false;
        this._hosts.forEach(h => h.destroy());
        delete this._hosts;
        delete this.parent;
        super.doDestroy();
    }

    /**
     * called on RAP "render" event
     */
    onRender() {
        this.ready = true;
        rap.off('render', this.onRender);
    }

    /**
     * called by the backend to activate or deactivate an edge
     * @param {*} args arguments
     */
    activateEdge(args) {
        const edge = args.edge;
        const activate = !!args.activate;
        if ( isValidEdge(edge) ) {
            this._hosts[edge].setAvailable(activate);
        } else {
            this.warn(`Invalid edge: "${edge}"!`);
        }
    }

    /**
     * called the backend to activate or deactivate all edge buttons
     * @param {*} args arguments
     */
    setAllActive(args) {
        const active = !!args.active;
        if ( this.active !== active ) {
            if ( this.isDebugEnabled() ) {
                this.log(`New general activation status: ${active}.`);
            }
            this._activateButtons(active);
        }
    }

    /**
     * called by the backend to suspend or resume edge button operations
     * @param {*} args arguments
     */
    setSuspended(args) {
        const suspended = !!args.suspended;
        if ( this.suspended !== suspended ) {
            this._suspended = suspended;
            this._activateButtons(this.active);
        }
    }

    /**
     * sets the direction of an edge button
     * @param {*} args arguments
     */
    setDirection(args) {
        const edge = args.edge;
        const dir = !!args.dir;
        if ( isValidEdge(edge) ) {
            this._hosts[edge].setDirection(dir);
        } else {
            this.warn(`Invalid edge: "${edge}"!`);
        }
    }

    setFixedPos(args) {
        const edge = args.edge;
        const beg = args.beg;
        const size = args.size;
        if ( isValidEdge(edge) && Validator.isPositiveNumber(beg, true) && Validator.isPositiveNumber(size, false) ) {
            this._hosts[edge].setFixedPos(beg, size);
        } else {
            this.warn(`Invalid arguments: "${args}"!`);
        }
    }

    /**
     * called by the backend to simulate an edge button click
     * @param {*} args arguments
     */
    simulateClick(args) {
        const edge = args.edge;
        if ( isValidEdge(edge) ) {
            if ( this.isDebugEnabled() ) {
                this.log(`Simulating click on edge button ${edge}.`);
            }
            this.onEdgeClicked(edge);
        } else {
            this.warn(`Invalid edge: "${edge}"!`);
        }
    }

    /**
     * called if an edge button was clicked
     * @param {Number} edge the edge who's button was clicked
     */
    onEdgeClicked(edge) {
        this._nfySrv('clicked', { edge: edge });
    }

    /**
     * initializes the edge button hosts
     * @param {Number} width the width (or height) in pixels of the edge button host elements
     * @param {Number} size the size in pixels of an edge button
     * @param {Boolean} active initial activation state
     */
    _initHosts(width, size, active) {
        // we support three edges: left, right and bottom
        this._hosts.push(new EdgeHost(this, EDGE_LEFT, width, size));
        this._hosts.push(new EdgeHost(this, EDGE_TOP, width, size));
        this._hosts.push(new EdgeHost(this, EDGE_RIGHT, width, size));
        this._hosts.push(new EdgeHost(this, EDGE_BOTTOM, width, size));
        this._activateButtons(active);
    }

    /**
     * activates or deactivates the edge buttons
     * @param {Boolean} active new active flag
     */
    _activateButtons(active) {
        this._active = active;
        const eff_act = this.active && !this.suspended;
        this._hosts.forEach(h => h.setVisible(eff_act));
    }

    /**
     * sends a notification to the backend
     * @param {String} code notification code
     * @param {*} par notification parameters
     */
	_nfySrv(code, par) {
		if ( this.ready ) {
			const tms = Date.now();
			const param = {};
			param.cod = code;
			param.par = par;
			param.tms = tms;
			rap.getRemoteObject(this).notify('EDGEBTNS_NFY', param);
		}
	}


    /** register custom widget type */
    static register() {
		console.debug('Registering custom widget EdgeButtons.');
		rap.registerTypeHandler("psawidget.EdgeButtons", {
			factory : function(properties) {
				return new EdgeButtons(properties);
			},
			destructor : 'destroy',
			properties : [ /* none */ ],
			methods : [ 'activateEdge', 'setAllActive', 'setSuspended', 'setDirection', 'setFixedPos', 'simulateClick' ],
			events : [ 'EDGEBTNS_NFY' ]
		} );

    }
}